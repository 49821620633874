<template>
  <v-navigation-drawer
    v-resize="onResize"
    :value="menuUsuario"
    @input="updateMenuUsuario"
    temporary
    right
    app
    width="360px"
  >
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn color="secondary" icon @click="$emit('update:menuUsuario', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <!--Usuario -->
    <v-container class="pt-0">
      <v-row>
        <!-- Avatar -->
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-avatar size="150" class="mr-md-2">
            <v-container>
              <img
                :src="
                  store_avatar
                    ? store_avatar
                    : require('@/assets/avatar_placeholder.png')
                "
              />
            </v-container>
          </v-avatar>
        </v-col>

        <!--Nome -->
        <v-col cols="12" class="d-flex justify-center align-center">
          <div
            style="letter-spacing: 0.06em !important;
            text-transform: none !important;"
            class="text-h6 mr-1"
            v-if="store_user_name"
          >
            {{ nome }}
          </div>
        </v-col>

        <!--Menu -->
        <v-col cols="12">
          <v-list nav class="px-0">
            <v-list-item-group>
              <v-list-item
                v-if="colaborador_id"
                class="accent px-4"
                height="40px"
                @click.prevent="openDialogShow"
              >
                <v-list-item-action>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    Meus Dados
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="accent px-4"
                height="40px"
                @click.prevent="openDialogPrefe"
              >
                <v-list-item-action>
                  <v-icon>mdi-account-cog</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    Preferências
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="accent px-4"
                height="40px"
                @click.prevent="deslogarUsuario"
              >
                <v-list-item-action>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text_body-1">
                    Sair
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <!--Theme -->
    <v-container>
      <div
        class="mb-0 pl-1 text-subtitle-2 font-weight-black text--primary"
        path="theme"
      >
        Theme
      </div>
      <v-row dense>
        <!--Light -->
        <v-col cols="6">
          <v-card
            flat
            height="48px"
            class="d-flex align-center justify-space-between px-4"
            :class="{ 'info white--text': light, accent: dark }"
            @click="setTemplate"
            >light
            <v-icon :dark="light" right>mdi-white-balance-sunny</v-icon>
          </v-card>
        </v-col>
        <!--Dark -->
        <v-col cols="6">
          <v-card
            flat
            height="48px"
            class="d-flex align-center justify-space-between px-4"
            :class="{ 'info white--text': dark, accent: light }"
            @click="setTemplate"
          >
            Dark
            <v-icon :dark="dark" right>mdi-weather-night</v-icon>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!--Language -->
    <v-container>
      <div
        class="mb-0 pl-1 text-subtitle-2 font-weight-black text--primary"
        path="theme"
      >
        Language
      </div>

      <v-row>
        <v-col cols="12">
          <LocaleSwitcher />
        </v-col>
      </v-row>
    </v-container>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-card flat class="body-2 accent py-2 text-center">
        SOFTPAR <span class="caption mb-2">&reg;</span> - {{ year }} v{{
          app_version
        }}
      </v-card>
    </template>
    <ProfileColaborador
      v-if="dialogProfile"
      :dialogProfile.sync="dialogProfile"
    />
    <Preferencias
      v-if="dialogPreferencia"
      :dialogPreferencia.sync="dialogPreferencia"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import { version } from "../../../../package";

export default {
  name: "MenuLateralDireitoColaborador",

  props: {
    menuUsuario: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    Preferencias: () =>
      import("../../../views/geral/preferences/Preferencias.vue"),
    ProfileColaborador: () => import("./ProfileColaborador.vue"),
    LocaleSwitcher: () => import("@/components/LocaleSwitcher"),
  },

  data() {
    return {
      dialogProfile: false,
      dialogPreferencia: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      apiUrl: null,
      app_version: version,
    };
  },

  computed: {
    year() {
      return format(new Date(), "yyyy");
    },
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      store_user_name: (state) => state.usuario.name,
      store_avatar: (state) => state.avatar,
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
    nome() {
      let splitName = this.store_user_name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      firstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
      lastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      let name = firstName + " " + lastName;
      return name;
    },
  },

  watch: {},

  methods: {
    updateMenuUsuario(e) {
      this.$emit("update:menuUsuario", e);
    },
    openDialogShow() {
      this.dialogProfile = true;
    },
    openDialogPrefe() {
      this.dialogPreferencia = true;
    },

    deslogarUsuario() {
      this.loading = true;
      this.$store.dispatch("Usuario/deslogarUsuario").then(() => {
        this.$router.push("/login");
        this.loading = false;
      });
    },
    setTemplate() {
      if (this.light) {
        this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        this.$ls.set("template", "dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        this.$ls.set("template", "light");
        this.$vuetify.theme.dark = false;
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      if (this.windowSize.x < 1264) {
        return (this.mini = false);
      }
    },
  },
  mounted() {
    this.onResize();
    console.log
  },
};
</script>

<style lang="scss" scoped>
.pattern-bg {
  background-color: #eeeeee !important;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
}

.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
